import * as React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function BlogPostTemplate({data}) {
  return (
    <Layout>
      <SEO title="Gracias" description="Gracias por tu mensaje" hasJsonLd />
      <div className="section wrapper">
        <div style={{padding: '38px'}} className="container">
          <div className="columns is-centered">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 28,
              }}
              className="column is-half has-text-centered"
            >
              <img
                src="/assets/images/mail2.png"
                alt="correo"
                className="image is-128x128"
              />
              <h1 className="title">¡Gracias!</h1>
              <p className="subtitle">
                Tu inscripción fue exitosa, pronto te enviaremos toda la
                información por correo electrónico.
              </p>
              <Link to="/" className="">
                <u>Volver al inicio</u>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
